import { Controller } from "@hotwired/stimulus"
import createAlert from '../packs/createAlert';

export default class extends Controller {
  static targets = [ "modal", "number", "ratingField" ]

  connect() {
    // console.log("Hello, recap feedback controller here!", this.element)
    // console.log(this.accoladeTargets)
    // console.log(this.containerTarget)
    // console.log(this.addButtonTarget)
    this.modal = this.hasModalTarget && new bootstrap.Modal(this.modalTarget);
  }

  selectNumber(event) {
    const selected = parseInt(event.target.innerText, 10);

    this.ratingFieldTarget.value = selected;

    for(let i = 0; i < this.numberTargets.length; i++) {
      if (i < selected) {
        this.numberTargets[i].classList.add('selected-feedback');
      } else {
        this.numberTargets[i].classList.remove('selected-feedback');
      }
    }
  }

  formSubmitted() {
    this.modal.hide();

    createAlert('success', 'Thank you for submitting feedback!')
  }

}

