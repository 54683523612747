import { Controller } from "@hotwired/stimulus"
import createAlert from '../packs/createAlert';

export default class extends Controller {
  static targets = [ "text", "form", "controls", "modal", "confettiContainer", "interviewSubject" ]

  connect() {
    // console.log("Hello, Stimulus from Stories Speaker Identification!", this.element)
    // console.log(this.textTarget)
    // console.log(this.textTarget.innerText);
    // console.log(this.formTarget);

    if (this.hasTextTarget && this.textTarget.innerText.includes('speaker_1')) {
      this.textTarget.innerHTML = this.textTarget.innerHTML.replace(/speaker_1/g, "<span class='speaker_1'>speaker_1</span>")
      this.textTarget.innerHTML = this.textTarget.innerHTML.replace(/speaker_2/g, "<span class='speaker_2'>speaker_2</span>")
    } else {
      if (this.hasControlsTarget) {
        this.controlsTarget.style.display = 'none';
      }
    }

    // for use in the FTP
    if (this.hasModalTarget) {
      if (localStorage.getItem('interviewer_speaker_identification') === 'true') {
        localStorage.removeItem('interviewer_speaker_identification');

        this.confettiContainerTarget.classList.remove('d-none');
        this.confettiContainerTarget.classList.add('d-block');

        const hoorayModal = new bootstrap.Modal(this.modalTarget);

        hoorayModal.show();

        const showConfetti = () => {
          const confetti = document.createElement("div");
          confetti.textContent = "🌭";
          confetti.classList.add("confetti");
          confetti.style.left = Math.random() * innerWidth + "px";
          this.confettiContainerTarget.appendChild(confetti);

          setTimeout(() => {
            confetti.remove();
          }, 3000);
        };

        const confettiLoop = setInterval(() => {
          showConfetti();
        }, 200);

        this.modalTarget.addEventListener('hidden.bs.modal', (event) => {
          clearInterval(confettiLoop);

          this.confettiContainerTarget.classList.remove('d-block');
          this.confettiContainerTarget.classList.add('d-none');
        })

      }
    }
  }

  updateSpeaker1(event) {
    let text = event.target.value;
    this.updateSpeaker(1, text);
  }

  updateSpeaker2(event) {
    let text = event.target.value;
    this.updateSpeaker(2, text);
  }

  updateSpeaker(id, text) {
    // console.log('Updating speaker text', id, text);
    // this.textTarget.innerHTML = newText;

    document.querySelectorAll(`.speaker_${id}`).forEach(elm => elm.innerText = text);
  }

  submitForm(event) {

    // if subject text input is present, make sure they fill it out!
    if (this.hasInterviewSubjectTarget && this.interviewSubjectTarget.value === '') {
      this.interviewSubjectTarget.classList.add('is-invalid');
      this.interviewSubjectTarget.focus();
      createAlert('danger', 'Please enter the subject of the interview');
      return;
    }

    const input = document.createElement("textarea");
    input.setAttribute("name", event.target.dataset.formElement);
    input.innerHTML = this.textTarget.innerText.replace('\r\n', '<br>');
    input.style.display = 'none';
    this.formTarget.appendChild(input);

    if (this.hasModalTarget) {
      localStorage.setItem('interviewer_speaker_identification', 'true');
    }

    this.formTarget.submit();
  }

}

