import { Controller } from "@hotwired/stimulus"
import createAlert from '../packs/createAlert';
import ratio from '../packs/ratio';
import debounce from 'lodash.debounce';

let doc, activeUIBlock, assignContentModal, waitForItModal, deferredImages = [];

export default class extends Controller {
  static targets = [ "assignContentModal", "waitForItModal", "chunk", "textArea", "formElement", "form" ]
  static values = {
    url: String,
    username: String,
    password: String,
    story: Number,
    publishedAt: Boolean,
    publishedPostId: String,
    publishedUrl: String
  }

  initialize() {
    this.checkSlugAvailability = debounce(this.checkSlugAvailability.bind(this), 500)
  }

  connect() {
    // console.log("Hello, Stories Publish Controller here!", this.element);
    // console.log("Published Post Id", this.publishedPostIdValue);

    waitForItModal = new bootstrap.Modal(this.waitForItModalTarget);

    if (!this.publishedAtValue) {
      assignContentModal = new bootstrap.Modal(this.assignContentModalTarget);
    }

    if (this.publishedPostIdValue) {
      // alert(`Got a post ID ${this.publishedPostIdValue}`);
      this.fetchPublishedPost();
    }
  }

  fetchPublishedPost(event) {
    const authHeader = btoa(`${this.usernameValue}:${this.passwordValue}`)
    fetch(`${this.urlValue}/wp-json/wp/v2/posts/${this.publishedPostIdValue}?context=edit&_embed=wp:featuredmedia`, {
      headers: {
        'Authorization': `Basic ${authHeader}`
      }
    })
    .then(resp => resp.json())
    .then(data => {
      // console.log('Published post', data);

      document.getElementById('publishTitle').value = data.title.rendered;
      document.getElementById('publishAuthor').value = data.fanword_author;
      document.getElementById('publishSlug').value = data.slug;
      document.getElementById('publishDraft').innerText = data.status;

      if (data.status === 'draft') {
        // if the post is a draft, the primary button would say "update" but needs to say Publish
        document.getElementById('submit-publish').value = 'Publish';
      }

      // set the featured media
      this.loadExistingImage('featuredMedia', data._embedded['wp:featuredmedia'][0].source_url);

      // The Wordpress API's raw results will return back the Gutenburg HTML comments
      // and the DOMParser screws up in the first node is an HTML comment (puts it outside of the body)
      // so we wrap the Gutenburg content in a div so there's no cling-ons
      let html = `<div id='container'>${data.content.raw}</div>`

      doc = (new DOMParser).parseFromString(html, "text/html");

      // grab groups from this
      const groups = doc.querySelectorAll('.wp-block-group');

      this.buildInputsFromTemplate(groups);

      // images cannot be loaded during the initial build, so we store the element ID
      // and URL references in an array when they are built and  need to load them after the fact
      this.loadDeferredImages();

      this.checkForCompletion();
    })
  }

  fetchTemplate(event) {
    const authHeader = btoa(`${this.usernameValue}:${this.passwordValue}`)
    fetch(event.target.value, {
      headers: {
        'Authorization': `Basic ${authHeader}`
      }
    }).then(resp => resp.json()).then(data => {

      // The Wordpress API's raw results will return back the Gutenburg HTML comments
      // and the DOMParser screws up in the first node is an HTML comment (puts it outside of the body)
      // so we wrap the Gutenburg content in a div so there's no cling-ons
      let html = `<div id='container'>${data.content.raw}</div>`

      doc = (new DOMParser).parseFromString(html, "text/html");

      // grab groups from this
      const groups = doc.querySelectorAll('.wp-block-group');

      this.buildInputsFromTemplate(groups);
    })
  }

  buildInputsFromTemplate(groups) {
    const accordion = document.querySelector('#publish-accordion');

    // reset the container
    accordion.innerHTML = "";

    groups.forEach((group, index) => {

      // create accordion group
      const item = document.createElement('div');
      item.classList.add('accordion-item');
      item.innerHTML = `
        <h2 class="accordion-header" id="group-header-${index}">
          <button class="accordion-button color-fanword-black collapsed py-4 px-2" type="button" data-bs-toggle="collapse" data-bs-target="#group-${index}" aria-expanded="true" aria-controls="group-${index}">
            <i id="indicator-${index}" class="fas fa-check-circle me-3 color-fanword-text"></i>
            Paragraph ${index + 1}
          </button>
        </h2>
        <div id="group-${index}" class="accordion-collapse collapse " aria-labelledby="group-header-${index}" data-bs-parent="#publish-accordion">
          <div class="accordion-body">
          </div>
        </div>
      `

      const itemBody = item.querySelector('.accordion-body')
      const elements = group.querySelectorAll('[id^="fanword"]');

      let elementToBuild, cachedSideBySideImage;

      elements.forEach(elm => {

        // curently we support side-by-side images in Wordpress templates with 1a, 1b
        // unfortunately, endsWith() will not accept a regex so we have to search for the specific
        // ending, which means we can't easily expand to N number of side-by-side images... 😶
        if (elm.id.endsWith('a')) {
          cachedSideBySideImage = elm;
          return;
        }

        if (cachedSideBySideImage) {
          elementToBuild = [cachedSideBySideImage, elm];
          cachedSideBySideImage = undefined;
        } else {
          elementToBuild = elm;
        }

        // build the form elements to keep track of the actual data
        const formMarkup = this.elementFactory(elementToBuild);

        itemBody.innerHTML = itemBody.innerHTML += formMarkup;
      }) // end elements loop

      accordion.appendChild(item);

    }) // end group loop

    // set up listeners for the completion indicators
    // console.log('targets', this.formElementTargets);
    this.formElementTargets.forEach(formElement => {
      formElement.addEventListener('change', this.checkForCompletion);
      formElement.addEventListener('input', this.checkForCompletion);
    });
  }

  elementFactory(elm) {
    if (Array.isArray(elm) || elm.id.includes('image')){

      if (Array.isArray(elm)) {
        const ratioArray1 = ratio(elm[0].dataset.fanwordWidth, elm[0].dataset.fanwordHeight);
        const ratioDecimal1 = ratioArray1[0]/ratioArray1[1];

        const ratioArray2 = ratio(elm[1].dataset.fanwordWidth, elm[1].dataset.fanwordHeight);
        const ratioDecimal2 = ratioArray2[0]/ratioArray2[1];

        const firstNode  = (new DOMParser).parseFromString(elm[0].innerHTML, "text/html");
        const firstUrl = firstNode.querySelector('img').src;
        deferredImages.push({ id: elm[0].id, url: firstUrl });

        const secondNode  = (new DOMParser).parseFromString(elm[1].innerHTML, "text/html");
        const secondUrl = secondNode.querySelector('img').src;
        deferredImages.push({ id: elm[1].id, url: secondUrl });

        return `
          <div class='mb-4'>
            <label class='form-label'>Photo</label>
            <div class="row">
              <div class="col-6" data-controller='crop' data-crop-width-value="${elm[0].dataset.fanwordWidth}" data-crop-height-value="${elm[0].dataset.fanwordHeight}" data-crop-aspect-ratio-value="${ratioDecimal1}" data-crop-file-name-value="side-by-side-a" data-action="crop:cropped->stories-publish#previewImage">
                <div id="preview_${elm[0].id}" class="text-center fanword-border color-fanword-text fanword-border-radius bg-fanword-background cursor-fanword-pointer d-flex flex-column justify-content-center position-relative" style="min-height:200px;background-size:cover;aspect-ratio:${ratioDecimal1}" data-action='click->stories-publish#triggerFileSelect' data-form-element-id="input_${elm[0].id}">
                  <div class='no-preview d-block pe-none'>
                    <p class="pe-none fs-1">+</p>
                    <p class="pe-none mb-2 font-fanword-bold">Click to upload photo</p>
                    <p class="pe-none m-0">1920x1280 recommended</p>
                  </div>
                  <div class='has-preview d-none position-absolute top-0 end-0 mt-2 me-2'>
                    <button type="button" data-action='click->stories-publish#removeImage:stop' data-element-id="${elm[0].id}" class='btn bg-fanword-white'>
                      <i class="fas fa-trash-alt color-fanword-black text-decoration-none pe-none"></i>
                    </button>
                  </div>
                </div>
                <input type='file' id='input_${elm[0].id}' data-id="${elm[0].id}" class='form-control p-3 d-none' data-stories-publish-target="formElement" data-crop-target='input' data-action='change->crop#changeHandler' />
              </div>

              <div class="col-6" data-controller='crop' data-crop-width-value="${elm[1].dataset.fanwordWidth}" data-crop-height-value="${elm[1].dataset.fanwordHeight}" data-crop-aspect-ratio-value="${ratioDecimal2}" data-crop-file-name-value="side-by-side-b" data-action="crop:cropped->stories-publish#previewImage">
                <div id="preview_${elm[1].id}" class="text-center fanword-border color-fanword-text fanword-border-radius bg-fanword-background cursor-fanword-pointer d-flex flex-column justify-content-center position-relative" style="min-height: 200px; background-size: cover; aspect-ratio:${ratioDecimal2}" data-action='click->stories-publish#triggerFileSelect' data-form-element-id="input_${elm[1].id}">
                  <div class='no-preview d-block pe-none'>
                    <p class="pe-none fs-1">+</p>
                    <p class="pe-none mb-2 font-fanword-bold">Click to upload photo</p>
                    <p class="pe-none m-0">1920x1280 recommended</p>
                  </div>
                  <div class='has-preview d-none position-absolute top-0 end-0 mt-2 me-2'>
                    <button type="button" data-action='click->stories-publish#removeImage:stop' data-element-id="${elm[1].id}" class='btn bg-fanword-white'>
                      <i class="fas fa-trash-alt color-fanword-black text-decoration-none pe-none"></i>
                    </button>
                  </div>
                </div>
                <input type='file' id='input_${elm[1].id}' data-id="${elm[1].id}" class='form-control p-3 d-none' data-stories-publish-target="formElement" data-crop-target='input' data-action='change->crop#changeHandler' />
              </div>
            </div>
          </div>
        `
      }

      const ratioArray = ratio(elm.dataset.fanwordWidth, elm.dataset.fanwordHeight);
      const ratioDecimal = ratioArray[0]/ratioArray[1];

      let url;
      if (this.publishedPostIdValue) {
        const node  = (new DOMParser).parseFromString(elm.innerHTML, "text/html");
        url = node.querySelector('img').src;
        deferredImages.push({ id: elm.id, url: url });
      }

      return `
        <div class='mb-4' data-controller='crop' data-crop-width-value="${elm.dataset.fanwordWidth}" data-crop-height-value="${elm.dataset.fanwordHeight}" data-crop-aspect-ratio-value="${ratioDecimal}" data-crop-file-name-value="fanword-image" data-action="crop:cropped->stories-publish#previewImage">
          <label class='form-label'>Photo</label>
          <div id="preview_${elm.id}" class="text-center fanword-border color-fanword-text fanword-border-radius bg-fanword-background cursor-fanword-pointer d-flex flex-column justify-content-center position-relative" style="min-height: 200px; background-size: cover; aspect-ratio:${ratioDecimal}" data-action='click->stories-publish#triggerFileSelect' data-form-element-id="input_${elm.id}">
            <div class='no-preview d-block pe-none'>
              <p class="pe-none fs-1">+</p>
              <p class="pe-none mb-2 font-fanword-bold">Click to upload photo</p>
              <p class="pe-none m-0">${elm.dataset.fanwordWidth}x${elm.dataset.fanwordHeight} recommended!</p>
            </div>
            <div class='has-preview d-none position-absolute top-0 end-0 mt-2 me-2'>
              <button type="button" data-action='click->stories-publish#removeImage:stop' data-element-id="${elm.id}" class='btn bg-fanword-white'>
                <i class="fas fa-trash-alt color-fanword-black text-decoration-none pe-none"></i>
              </button>
            </div>
          </div>
          <input type='file' id='input_${elm.id}' data-id="${elm.id}" class='form-control p-3 d-none' data-stories-publish-target="formElement" data-crop-target='input' data-action='change->crop#changeHandler' />
        </div>
      `
    }

    if (elm.id.includes('text')){
      return `
        <div class='mb-4'>
          <label class='form-label'>Paragraph</label>
          <textarea id='input_${elm.id}' rows="6" data-id="${elm.id}" ${ this.publishedPostIdValue ? '' : 'readonly' } class='form-control p-3 ${ this.publishedPostIdValue ? 'color-fanword-black bg-fanword-white' : 'color-fanword-text bg-fanword-background cursor-fanword-pointer' }' data-stories-publish-target="formElement textArea" data-action="click->stories-publish#presentModal" placeholder="Click to assign the paragraph">${ this.publishedPostIdValue ? elm.innerHTML.replace(/<br><br>/g, '\n\n').trim() : '' }</textarea>
        </div>
      `
    }

    if (elm.id.includes('subtitle')){
      return `
        <div class='mb-4'>
          <label class='form-label'>Subtitle</label>
          <input type='text' id='input_${elm.id}' data-id="${elm.id}" class='form-control p-3' data-stories-publish-target="formElement" value="${ this.publishedPostIdValue ? elm.innerHTML.replace(/<br><br>/g, '\n\n').trim() : '' }" />
        </div>
      `
    }

    if (elm.id.includes('quote')){

      let author, quote;
      if (this.publishedPostIdValue) {
        const node  = (new DOMParser).parseFromString(elm.innerHTML, "text/html");
        quote = node.querySelector('p').innerHTML;
        author = node.querySelector('cite').innerHTML;
      }

      return `
        <div class='mb-4'>
          <label class='form-label'>Quote Text</label>
          <textarea id='input_${elm.id}-text' data-id="${elm.id}" data-quote-part="text" class='form-control p-3' data-stories-publish-target="formElement">${ this.publishedPostIdValue ? quote : '' }</textarea>
        </div>
        <div class='mb-4'>
          <label class='form-label'>Quote Author</label>
          <input type='text' id='input_${elm.id}-author' data-id="${elm.id}" data-quote-part="author" class='form-control p-3' data-stories-publish-target="formElement" value="${ this.publishedPostIdValue ? author : '' }" />
        </div>
      `
    }

    // shouldn't get here
  }

  presentModal(event) {
    activeUIBlock = event.target.id;
    assignContentModal.show();
  }

  triggerFileSelect(event) {
    event.stopPropagation();

    if (!event.target.dataset.formElementId) {
      return;
    }

    // console.log('publish_controller#triggerFileSelect', event.target.dataset.formElementId);

    document.querySelector(`#${event.target.dataset.formElementId}`).click();
  }

  previewImage(event) {
    if (event.detail.content.files && event.detail.content.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        const container = document.querySelector(`#preview_${event.detail.content.dataset.id}`);

        // set the preview as the url
        container.style.backgroundImage = `url(${e.target.result})`;

        // delete the previous data attribute, since a new image is being used
        delete event.detail.content.dataset['previous'];

        // remove the wording, enable the trash icon
        container.querySelector('.no-preview').classList.remove('d-block');
        container.querySelector('.no-preview').classList.add('d-none');
        container.querySelector('.has-preview').classList.remove('d-none');
        container.querySelector('.has-preview').classList.add('d-block');
      };

      reader.readAsDataURL(event.detail.content.files[0]);
    }
  }

  removeImage(event) {
    // console.log('Attempting to remove an image!', event);
    event.preventDefault();
    event.stopPropagation();

    const previewElement = document.querySelector(`#preview_${event.target.dataset.elementId}`);
    previewElement.style.backgroundImage = 'none';

    // remove the wording, enable the trash icon
    previewElement.querySelector('.has-preview').classList.remove('d-block');
    previewElement.querySelector('.has-preview').classList.add('d-none');
    previewElement.querySelector('.no-preview').classList.remove('d-none');
    previewElement.querySelector('.no-preview').classList.add('d-block');

    const formElement = document.querySelector(`#input_${event.target.dataset.elementId}`);
    formElement.value = null;
    delete formElement.dataset['previous'];

    var event = document.createEvent("UIEvents");
    event.initUIEvent("change", true, true);
    formElement.dispatchEvent(event);
  }

  toggleChunkActive(event) {
    // console.log('Togglging chunk', event);

    // if its an assigned chunk, we need to remove that class and de-assign it from the UI Block
    if (event.target.classList.contains('assigned-chunk')) {
      // remove all markings of previously assigned chunk
      [...event.target.classList].forEach(string => { string.startsWith('fanword') ? event.target.classList.remove(string) : '' })
      event.target.classList.remove('assigned-chunk');
      event.target.dataset['assignedTo'] = "";
    } else {
      event.target.classList.toggle('staged-chunk');
    }

  }

  assignContent(event) {
    // console.log('Assigning content!', event);

    const activeTextArea = document.querySelector(`#${activeUIBlock}`);

    // remove the value for ALL of textareas
    this.textAreaTargets.forEach(target => target.value = "");

    // assign all still assigned chunks back to textareas
    const stillAssignedChunks = this.chunkTargets.filter(elm => elm.classList.contains(`assigned-chunk`));
    stillAssignedChunks.forEach(chunk => {
      let formElement = document.querySelector(`#${chunk.dataset.assignedTo}`)
      formElement.value = formElement.value + "\n\n" + chunk.innerText + "\n\n";

      // trim excess linebreaks off the end of the string value
      formElement.value = formElement.value.trimEnd();
      formElement.value = formElement.value.trimStart();
    });

    // get the chunks that were selected
    const activeChunks = this.chunkTargets.filter(elm => elm.classList.contains('staged-chunk'))

    // update the UI Block to show what text is present AND update the form elements
    activeChunks.forEach(chunk => {
      // mark them so we know what UI block they belong to
      chunk.dataset['assignedTo'] = activeUIBlock;
      chunk.classList.add("assigned-chunk", activeUIBlock);
      chunk.classList.remove('staged-chunk');

      // let formElement = document.querySelector(`#${activeUIBlock}`);
      activeTextArea.value = activeTextArea.value + chunk.innerText + "\n\n";
    });

    // trim excess linebreaks off the end of the string value
    activeTextArea.value = activeTextArea.value.trimEnd();

    // textareas don't get updated when programmatically setting content, so trigger it
    // https://stackoverflow.com/a/60644856/1814019
    activeTextArea.dispatchEvent(new InputEvent('change'))

    assignContentModal.hide();
  }

  async publishStory(event) {
    event.preventDefault();

    if (Array.from(event.target.elements).some(elm => elm.classList.contains('is-invalid'))) {
      createAlert('danger', 'Invalid selections. Please correct the form.')
      return;
    }

    waitForItModal.show();

    const controls = Array.from(event.srcElement)
      .filter(elm => !['submit', 'button'].includes(elm.type))
      .filter(elm => !['input_featuredMedia', 'publishTitle', 'publishAuthor', 'publishSlug'].includes(elm.id)) // ignore the static fields

    for (const elm of controls) {

      // if there's no selected file and no pre-existing WP image, we can't proceed
      if (!elm.value && !elm.dataset['previous']) {
        createAlert('danger', 'Not all sections have been filled out!')
        waitForItModal.hide();
        return;
      }

      const templateId = elm.id.split('_')[1];

      if (!templateId) {
        // the static form fields at the top will not need injecting into the template
        continue;
      }

      // differentiate how quotes and images are injected into the Gutenburg template
      if (templateId.includes('quote')) {
        // quotes in Wordpress are a single UI element, so we need to reduce it down to primary ID
        const parts = templateId.split('-');
        parts.pop();
        const wordpressTemplateId = parts.join('-')

        const figure = doc.querySelector(`#${wordpressTemplateId}`);
        if (elm.dataset.quotePart === 'text') {
          figure.querySelector('p').innerHTML = elm.value;
        } else if (elm.dataset.quotePart === 'author') {
          figure.querySelector('cite').innerHTML = elm.value;
        }

      } else if (templateId.includes('image')) {

        // need to upload the image first and get the Wordpress hosted URL
        const figure = doc.getElementById(`${templateId}`);
        const image = figure.querySelector('img')

        let imageSource;
        if (elm.dataset['previous']) {
          imageSource = elm.dataset['previous'];
        } else {

          // upload the file
          const wpImage = await this.uploadImage(elm);

          if (wpImage) {
            imageSource = wpImage?.guid?.rendered;
          }
        }

        image.src = imageSource;

        // since the upload is an async process, we need to signal that the loop can continue
        continue;

      } else {
        doc.querySelector(`#${templateId}`).innerHTML = elm.value.replace(/\n\n/g, '<br><br>');
      }

    };

    // deal with featured media
    const featuredMedia = await this.uploadImage(document.querySelector('#input_featuredMedia'));

    // console.log('publishing the post', doc.body.querySelector('#container').innerHTML)

    let status;
    if (event.submitter.value.includes('Draft')) {
      status = 'draft';
    } else {
      status = 'publish';
    }

    // should have the template filled out, now need to submit it to Wordpress
    fetch(`${this.urlValue}/wp-json/wp/v2/posts${ this.publishedPostIdValue ? '/' + this.publishedPostIdValue : '' }`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${this.usernameValue}:${this.passwordValue}`)}`
      },
      body: JSON.stringify({
        status: status,
        title: document.querySelector('#publishTitle').value,
        author: 1, // TODO will need customized probably
        content: doc.body.querySelector('#container').innerHTML,
        slug: document.querySelector('#publishSlug').value,
        featured_media: featuredMedia.id,
        excerpt: document.querySelector('#publishAuthor').value,
        fanword_author: document.querySelector('#publishAuthor').value
      })
    })
    .then(publishResults => publishResults.json())
    .then(async postData => {

      await fetch(`/stories/${this.storyValue}/publish/wordpress`, {
        method: 'POST',
        body: JSON.stringify({ url: postData.link, id: postData.id })
      });

      // dismiss wait modal
      waitForItModal.hide();

      // reload the page
      let url = window.location.href;
      window.location.href = url;

    }).catch( (error) => {
      // debugger;
      console.log('Wordpress publishing error', error);
      createAlert('warning', 'Wordpress page not published')
    })

  }

  uploadImage(formElement) {
    const file = formElement.files[0]

    if (!file) {
      return false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("title", "fanword-publish-story");

    return fetch(`${this.urlValue}/wp-json/wp/v2/media`, {
      method: 'POST',
      headers: {
        'Content-Disposition': `attachment; filename="${file.name}"`,
        'Authorization': `Basic ${btoa(`${this.usernameValue}:${this.passwordValue}`)}`
      },
      body: formData,
      redirect: 'follow'
    })
    .then((response) => response.json())
    .then(result => {
      // console.log(result);
      return result;
    })
    .catch( (error) => {
      console.log(error);
    })
  }

  loadDeferredImages() {
    deferredImages.forEach(image => {
      this.loadExistingImage(image.id, image.url);
    });
  }

  loadExistingImage(formElementId, imageUrl) {
    const formElement = document.querySelector(`#input_${formElementId}`);
    const previewElement = document.querySelector(`#preview_${formElementId}`);

    previewElement.style.backgroundImage = `url(${imageUrl})`;

    // remove the wording, enable the trash icon
    previewElement.querySelector('.no-preview').classList.remove('d-block');
    previewElement.querySelector('.no-preview').classList.add('d-none');
    previewElement.querySelector('.has-preview').classList.remove('d-none');
    previewElement.querySelector('.has-preview').classList.add('d-block');

    formElement.dataset['previous'] = imageUrl;
  }

  checkForCompletion(event) {
    if (event) {
      event.preventDefault();
    }

    // grab all of the groups
    const groups = document.querySelectorAll('#publish-accordion .accordion-item')

    // check all of the inputs in the group
    groups.forEach((group, index) => {
      const indicator = group.querySelector(`#indicator-${index}`);

      const controls = Array.from(group.querySelectorAll('input, textarea'));

      if(controls.every(control => (control.value && control.value !== "") || control.dataset['previous'])) {
        // if all have a value, set marker to green
        indicator.classList.remove('color-fanword-text');
        indicator.classList.add('color-fanword-green');
      } else {
        // if not, unset green class
        indicator.classList.remove('color-fanword-green');
        indicator.classList.add('color-fanword-text');
      }

    });


  }

  checkSlugAvailability(event) {
    const slug = event.target.value;

    if (slug === "") {
      // remove invalid state from form control
      event.target.classList.remove('is-invalid');
      event.target.classList.remove('is-valid');

      // remove help text
      event.target.nextElementSibling.innerHTML = "";

      return;
    }

    fetch(`${this.urlValue}/wp-json/wp/v2/posts?slug=${slug}`)
    .then(response => response.json())
    .then(data => {
      // console.log('Got a response', data);
      if (data.length > 0) {

        // add invalid state to form control
        event.target.classList.add('is-invalid');

        // add help text
        event.target.nextElementSibling.innerHTML = 'Slug already in use';

      } else {
        // remove invalid state from form control
        event.target.classList.remove('is-invalid');
        event.target.classList.add('is-valid');

        // remove help text
        event.target.nextElementSibling.innerHTML = "";
      }

    });

  }

  viewDraftPost(event) {
    event.preventDefault();

    const authHeader = btoa(`${this.usernameValue}:${this.passwordValue}`)
    window.open(`${this.publishedUrlValue}${this.publishedUrlValue.includes('?') ? '&' : '?'}access_token=${authHeader}`, '_blank');
  }

}
